<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  export let price
</script>

<Section center="true" background="very-light-grey">
  <h2 class="font-semibold mb-4">
    ¿Cuántos sensores OBD2 pueden hacer todo eso...por solo $19.99?
    {price.toString()}?
  </h2>

  <h2 class="text-30 font-black">CERO.</h2>

  <p class="text-lg mb-8 mt-4">
    FIXD funciona con todos los vehículos de gasolina de 1996 o posteriores y es
    compatible con todos los dispositivos iOS y Android. Para que FIXD llegue a
    manos del mayor número posible de <span class="font-bold">conductores</span
    >, lo ofrecemos al mejor precio de la historia. Así que, ¿qué estás
    esperando?
  </p>

  <slot name="cta" />
</Section>
