<script>
  import BFCM from '@/pages/scorpio/bfcm/OldBFCM.svelte'

  export let funnel
  export let gateway
</script>

<main class="md:pb-0">
  <BFCM {funnel} {gateway} />
</main>
