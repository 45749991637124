<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Image from '@/components/Image.svelte'

  import phoneImg from '@/images/landing-page/Sensor67/white_phone_and_sensor_transparent.png?width=500&as=picture'
  import iconCarsURL from '@/images/landing-page/Sensor67/Cars.png?w=70'
  import iconCelURL from '@/images/landing-page/Sensor67/OrangeEngine.png?w=70'
  import iconCheckURL from '@/images/landing-page/Sensor67/GreenCheckMark.png?w=70'
  import iconWirelessURL from '@/images/landing-page/Sensor67/wireless.png?w=70'
  import iconWrenchesURL from '@/images/landing-page/Sensor67/RedWrench.png?w=70'
  import iconDetailsURL from '@/images/landing-page/Sensor67/Magnify.png?w=70'

  const benefits = [
    {
      icon: iconCelURL,
      title: 'Verificar el motor (Check Engine)',
      body: `¿Hay una lucecita en el control del motor? FIXD te informa al instante y en profundidad qué es lo que va mal en términos claros y sencillos directamente en tu teléfono inteligente.`,
    },
    {
      icon: iconWrenchesURL,
      title: 'Gravedad del problema',
      body: `Averigua la gravedad del problema para saber si puedes seguir conduciendo sin peligro. ¡Incluso puedes apagar la luz si no es nada grave!`,
    },
    {
      icon: iconCheckURL,
      title: 'Seguimiento del mantenimiento',
      body: `Nuestro algoritmo realiza un seguimiento de tu kilometraje y te avisa automáticamente cuando llega el momento del mantenimiento rutinario para que puedas evitar costosas reparaciones y averías.`,
    },
    {
      icon: iconDetailsURL,
      title: 'Diagnóstico detallado',
      body: `A diferencia de otros sensores OBD2, FIXD proporciona información detallada y fácil de entender para ayudarte a sentirte seguro en el taller mecánico o incluso para que completes las reparaciones por tu cuenta.`,
    },
    {
      icon: iconCarsURL,
      title: 'Control multivehículo',
      body: `Supervisa todos tus vehículos desde una sola cuenta, lo que facilita la programación de servicios para los miembros de la familia, incluso si tú no estás presente.`,
    },
    {
      icon: iconWirelessURL,
      title: 'Evita costosas reparaciones',
      body: `FIXD se sincroniza automáticamente con tu teléfono inteligente a través de Bluetooth mientras conduces para que puedas controlar continuamente el estado de tu coche y resolver rápidamente cualquier problema.`,
    },
  ]
</script>

<Section center="true">
  <slot name="header">
    <div class="mb-7">
      <h2 class="text-30">
        Esta es la razón por la que FIXD es más que otro lector de códigos
      </h2>
    </div>
  </slot>

  <p class="mb-4">
    FIXD no solo te da acceso a información detallada del motor que puedes
    utilizar para estar más informado cuando vas con el mecánico, sino que
    también incluye poderosas funciones para ayudarte a ahorrar dinero y evitar
    costosas reparaciones y averías...
  </p>

  <div
    class="benefits text-center flex flex-col md:flex-row flex-nowrap items-center text-center"
  >
    <div class="flex flex-col flex-nowrap basis-full">
      {#each benefits.slice(0, 3) as benefit}
        <div class="benefit mb-4">
          <div
            class="flex flex-col justify-center md:justify-start flex-nowrap items-center text-center mb-3"
          >
            <img src={benefit.icon} alt={benefit.title} class="max-w-[70px]" />
            <h3 class="text-xl m-0">{benefit.title}</h3>
          </div>
          <p class="text-center mt-2">{benefit.body}</p>
        </div>
      {/each}
    </div>

    <div class="flex flex-col flex-nowrap basis-full">
      <Image
        image={phoneImg}
        alt="The FIXD app and sensor"
        --width="100%"
        media={{ default: { '1x': 500, '2x': 1000 } }}
      />
    </div>

    <div class="flex flex-col flex-nowrap basis-full">
      {#each benefits.slice(3, 6) as benefit}
        <div class="benefit mb-4">
          <div
            class="flex flex-col justify-center flex-nowrap items-center text-center mb-4"
          >
            <img src={benefit.icon} alt={benefit.title} class="max-w-[70px]" />
            <h3 class="text-xl m-0">{benefit.title}</h3>
          </div>
          <p class="text-center mt-2">{benefit.body}</p>
        </div>
      {/each}
    </div>
  </div>

  <slot name="cta" />
</Section>
