<script>
  import { Splide, SplideTrack } from '@splidejs/svelte-splide'
  import CarouselItem from '@/components/CarouselItem.svelte'
  import { StarFilledRound } from '@/components/icons'

  export let images
  export let fullImage = false

  $: imageIndex = 0

  let splideRef

  const changeImageIndex = (index) => {
    imageIndex = index
    splideRef.go(index + 1)
  }
</script>

<div class="flex items-center">
  <div class="shrink-0 space-y-2.5 hidden xl:block">
    {#each images.slice(1, 6) as item, i}
      <div
        class="hover:cursor-pointer border-2 rounded"
        class:border-green={imageIndex === i}
        role="button"
        on:click={() => changeImageIndex(i)}
        on:keypress={() => changeImageIndex(i)}
        tabindex="-1"
      >
        <img
          src={item.url}
          alt={item.altText}
          class="size-20 2xl:size-[100px]"
        />
      </div>
    {/each}
  </div>

  <div class="grow h-full w-full ml-3 mr-11">
    <Splide
      bind:this={splideRef}
      hasTrack={false}
      options={{
        arrows: false,
        pagination: false,
      }}
      on:moved={(e) => (imageIndex = e.detail.index - 1)}
    >
      <SplideTrack>
        <CarouselItem>
          <div class="bg-[#FAFAF6] h-full relative">
            {#if images[0].header !== undefined && images[0].header.length}
              <p
                class="font-bold text-xl 2xl:text-26 text-center pt-7 absolute w-full text-center text-black leading-tight"
              >
                Descifra las luces de control del motor (check engine) al
                instante
              </p>
            {/if}
            <div class="flex items-center justify-center h-full">
              <img
                src={images[0].url}
                alt={images[0].altText}
                class={images[0].class === undefined
                  ? fullImage
                    ? 'w-full md:w-4/5'
                    : 'w-3/5'
                  : images[0].class}
              />
            </div>
          </div>
        </CarouselItem>
        {#each images.slice(1, 6) as item}
          <CarouselItem>
            <div class="flex items-center justify-center h-full">
              <img src={item.url} alt={item.altText} />
            </div>
          </CarouselItem>
        {/each}
      </SplideTrack>
    </Splide>
    <div class="flex shrink-0 space-x-2.5 my-2 block xl:hidden justify-center">
      {#each images.slice(1, 6) as item, i}
        <div
          class="hover:cursor-pointer border-2 rounded"
          class:border-green={imageIndex === i}
          role="button"
          on:click={() => changeImageIndex(i)}
          on:keypress={() => changeImageIndex(i)}
          tabindex="-1"
        >
          <img src={item.url} alt={item.altText} class="2xl:size-[100px]" />
        </div>
      {/each}
    </div>

    <div class="flex mt-2 items-center flex-wrap gap-1">
      <p class="font-bold text-sm 2xl:text-base text-center">12K opiniones</p>
      <p class="text-grey-300 2xl:px-2">|</p>
      <div class="flex items-center pr-2">
        <p class="font-bold pr-1 text-sm 2xl:text-base">4.4</p>
        <div class="flex space-x-0.5">
          {#each new Array(5) as _}
            <StarFilledRound color="yellow" size="xs" />
          {/each}
        </div>
      </div>
      <div class="rounded flex items-center grow">
        <div
          class="bg-green min-w-[22px] min-h-[22px] flex items-center justify-center rounded-l"
        >
          <StarFilledRound color="white" size="xs" />
        </div>
        <div
          class="bg-[#f3f8f1] min-h-[22px] pl-2 flex items-center rounded-r w-full"
        >
          <p class="text-green text-xs font-bold">
            todas las reseñas de compras verificadas
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
