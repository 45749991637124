<script>
  import mainImage from '@/images/landing-page/Sensor67/mission_to_revolutionize.jpg'
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<Section verticalPadding="none">
  <div class="py-[60px]">
    <div class="mb-5">
      <img
        src={mainImage}
        alt="We're on a mission to revolutionize car care"
        class="w-full"
      />
    </div>

    <div class="text-center">
      <h4 class="font-extrabold mb-4">
        Nuestra misión es revolucionar el cuidado de los automóviles
      </h4>
      <p class="px-2">
        FIXD es una empresa de capital estadounidense con sede en Atlanta,
        Georgia, fundada por 3 graduados de Georgia Tech con la misión de
        aportar equidad y transparencia al sector de la reparación de
        automóviles. FIXD cree que el conocimiento es poder. Por eso ayudan a
        los conductores de todo el mundo a ahorrar dinero y a sentirse seguros
        en el taller mecánico. Únete a la revolución del cuidado de los
        automóviles.
      </p>
    </div>
  </div>
</Section>
