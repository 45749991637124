<script>
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import Footer from '@/components/landing-page/Footer.svelte'
  import Hero from '@/pages/scorpio/components/io/01-1Hero.svelte'
  import AsSeenOn from '@/pages/scorpio/components/io/02AsSeenOn.svelte'
  import Quote from '@/pages/scorpio/components/io/03Quote.svelte'

  import OtherWays from '@/pages/scorpio/components/io/05-1OtherWays.svelte'
  import HowItWorks from '@/pages/scorpio/components/io/06HowItWorks.svelte'
  import CompatibilityWidget from '@/pages/scorpio/components/io/07CompatibilityWidget.svelte'
  import BuyBox from '@/pages/scorpio/components/io/08BuyBox.svelte'

  import FAQ from '@/pages/scorpio/components/io/11FAQ.svelte'
  import About from '@/pages/scorpio/components/io/12About.svelte'
  import OtherWays2 from '@/pages/scorpio/components/io/05-2OtherWays.svelte'

  import MeetFixd from '@/pages/scorpio/components/io/04-1MeetFixd.svelte'

  import Bonus from '@/pages/scorpio/components/io/06Bonus.svelte'
  import Phones from '@/pages/scorpio/components/io/07Phones.svelte'
  import Everything from '@/pages/scorpio/components/io/08Everything.svelte'

  import Benefits from '@/pages/scorpio/components/io/Benefits.svelte'

  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Experiment from '@/components/Experiment.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Banner from '@/components/landing-page/Banner.svelte'

  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import AsSeenOn67 from '@/components/landing-page/AsSeenOn.svelte'
  import OtherOBD2 from '@/components/landing-page/OtherOBD2.svelte'
  import Benefits67 from '@/components/landing-page/Benefits.svelte'
  import TryRiskFree from '@/components/landing-page/TryRiskFree.svelte'
  import Mission from '@/components/landing-page/Mission.svelte'
  import FAQ67 from '@/components/landing-page/FAQ.svelte'
  import About67 from '@/components/landing-page/About.svelte'

  import Money from '@/util/money.mjs'

  import {
    Lightning,
    SalesTag,
    ShippingTruck,
    Certificate,
  } from '@/components/icons'

  export let funnel
  export let gateway

  const price = new Money({ amount: 19.99, currency: 'USD' })
</script>

<main class="md:pb-0">
  <Experiment name="2024-11-04-Irresistible-Offer" let:value>
    {#if value === 'Version 3' || value === 'Version 4'}
      <Hero>
        <div class="flex justify-center md:justify-start w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-2xl">try FIXD FOR $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Hero>

      <AsSeenOn invert={true} />
    {:else}
      <div class="hidden md:block">
        <div class="banner bg-green py-2.5">
          <div
            class="flex justify-evenly w-100 text-white font-bold font-inter"
          >
            <div class="flex items-center">
              <div class="mr-3">
                <SalesTag color="white" />
              </div>
              <p class="text-xl">Get 67% off!</p>
            </div>
            <div class="flex basis-1/3 border-x justify-center item-center">
              <div class="mr-3">
                <ShippingTruck color="white" />
              </div>
              <p class="text-xl">Free Shipping</p>
            </div>
            <div class="flex">
              <div class="mr-3">
                <Certificate color="white" />
              </div>
              <p class="text-xl">Free 1 Year Warranty</p>
            </div>
          </div>
        </div>
        <Banner />
      </div>

      <div class="block md:hidden">
        <div class="flex font-inter items-center leading-tight">
          <div class="bg-yellow py-1 px-2 h-8">
            <p class="uppercase font-bold text-10 text-center">
              Lightning <br /> deal
            </p>
          </div>
          <div class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full">
            <Lightning color="white" size="sm" />
            <p class="text-white my-0 text-center font-semibold text-xs">
              67% OFF + FREE SHIPPING + FREE WARRANTY
            </p>
          </div>
        </div>
        <Banner showMenu={true} height="small" fillShoppingCardIcon={false} />
      </div>

      <div class="block md:hidden">
        <Section center="true" verticalPadding="none">
          <div class="pt-5">
            <div class="font-inter mt-[-8px]">
              <h1 class="text-lg sm:text-4xl title bold" id="bannerHeader">
                Decode check engine lights instantly
              </h1>
            </div>
          </div>
        </Section>
      </div>
    {/if}

    <div class="max-w-[1440px] mx-auto mt-4 md:mt-10">
      <h1
        class="md:hidden font-extrabold text-center mb-4"
        class:hidden={value !== 'Version 3' && value !== 'Version 4'}
      >
        Try FIXD And Get $388 In Money-Saving Car Features For Only $19.99!
      </h1>
      <BuyBox
        {funnel}
        {gateway}
        controlVersion={value !== 'Version 3' && value !== 'Version 4'}
      />
    </div>

    {#if value === 'Version 3' || value === 'Version 4'}
      <Quote
        header={'"Paid for itself the first time I used it."'}
        showQuotation={false}
      >
        <p slot="description" class="font-medium text-xl">
          Mark Michael, <span class="italic">Verified Buyer</span>
        </p>
      </Quote>
    {:else}
      <AsSeenOn67 />
      <Benefits67 />
    {/if}

    {#if value === 'Version 3'}
      <MeetFixd>
        <div class="flex justify-center md:justify-start w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Save $230+ instantly</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </MeetFixd>

      <OtherWays>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </OtherWays>

      <OtherWays2>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </OtherWays2>

      <Bonus />

      <Phones>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Phones>
    {:else if value === 'Version 4'}
      <Benefits>
        <div class="flex justify-center w-full mt-10" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Benefits>
    {/if}

    {#if value === 'Version 3' || value === 'Version 4'}
      <Everything>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Everything>
      <HowItWorks>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Get $230+ in savings</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </HowItWorks>

      <CompatibilityWidget>
        <h1 class="font-extrabold" slot="header">
          Wondering If FIXD Works With Your Car?
        </h1>

        <div slot="content" class="my-10 md:mb-16">
          <p class="font-medium">
            FIXD works with all gas-powered cars and trucks <br
              class="hidden md:block"
            />built after 1996 and diesels built after 2008.
          </p>
        </div>
        <JumpToBuyBoxButton slot="compatible" maxWidth={56}>
          <span class="uppercase">BUY FIXD NOW</span>
        </JumpToBuyBoxButton>
      </CompatibilityWidget>

      <LiveReviews backgroundColor="white" />

      <FAQ background="bg-grey-200" />

      <About />
      <Footer />
    {:else}
      <OtherOBD2 {price}>
        <JumpToBuyBoxButton slot="cta">
          <span class="uppercase">Get FIXD NOW 67% OFF</span>
        </JumpToBuyBoxButton>
      </OtherOBD2>

      <Compatibility>
        <JumpToBuyBoxButton slot="cta" maxWidth={56}>
          <span class="uppercase">BUY FIXD NOW</span>
        </JumpToBuyBoxButton>
      </Compatibility>

      <LiveReviews />

      <TryRiskFree>
        <JumpToBuyBoxButton slot="cta" size="medium">
          <span class="uppercase">Get FIXD NOW 67% OFF</span>
        </JumpToBuyBoxButton>
      </TryRiskFree>

      <Mission />

      <FAQ67 />

      <About67 />

      <Footer />
    {/if}
  </Experiment>
</main>
