<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import foundersImg from '@/images/landing-page/3-tech-grads-illustration1.png?width=200&as=picture'
  import Image from '@/components/Image.svelte'
</script>

<Section center="true" background="very-light-grey">
  <div class="about">
    <Image image={foundersImg} alt="The founders" />
    <slot name="header">
      <h1 class="font-bold text-30 my-0">Sobre FIXD</h1>
    </slot>

    <p class="mx-auto w-9/12 mt-2">
      FIXD tiene su sede en Atlanta, Georgia, y se enorgullece de ser una
      empresa de capital estadounidense. Fundada por John Gattuso, Julian Knight
      y Frederick Grimm, tres graduados de Georgia Tech hartos de ver cómo eran
      estafados sus amigos y familiares en el taller mecánico, FIXD ha vendido
      más de 2 millones de sensores a conductores de todo Estados Unidos.
      Quieren ayudar a los propietarios de vehículos de todo el mundo a ahorrar
      tiempo y dinero y simplificar el cuidado de su carro.
    </p>
    <slot name="cta" />
  </div>
</Section>
