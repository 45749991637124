<script>
  import Benefits from '@/components/landing-page/Benefits.svelte'
  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import AsSeenOn from '@/components/landing-page/AsSeenOn.svelte'
  import OtherOBD2 from '@/components/landing-page/OtherOBD2.svelte'
  import TryRiskFree from '@/components/landing-page/TryRiskFree.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'
  import Mission from '@/components/landing-page/Mission.svelte'
  import FAQ from '@/components/landing-page/FAQ.svelte'
  import About from '@/components/landing-page/About.svelte'
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Banner from '@/components/landing-page/Banner.svelte'
  import { Lightning } from '@/components/icons'

  import BuyBox from '@/pages//scorpio/components/L007/BuyBoxL007.svelte'
  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Money from '@/util/money.mjs'

  export let funnel
  export let gateway

  const defaultOffer = funnel.initial_offers.find(
    (offer) => offer?.slug === funnel?.default_initial_offer_slug,
  )

  const price = defaultOffer?.subtotal_price
    ? new Money(defaultOffer?.subtotal_price)
    : new Money({ amount: 21.99, currency: 'USD' })
</script>

<main class="md:pb-0">
  <div class="block md:hidden">
    <div class="flex font-inter items-center leading-tight">
      <div class="bg-yellow py-1 px-2 h-8">
        <p class="uppercase font-bold text-10 text-center">
          Lightning <br /> deal
        </p>
      </div>
      <div class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full">
        <Lightning color="white" size="sm" />
        <p class="text-white my-0 text-center font-semibold text-xs">
          63% OFF + FREE SHIPPING + FREE WARRANTY
        </p>
      </div>
    </div>
    <Banner
      showMenu={true}
      height="small"
      fillShoppingCardIcon={false}
      buyUrl="/buy/sensor22"
    />
  </div>

  <div class="hidden md:block">
    <div class="banner bg-green">
      <p class="text-white my-0 text-center font-bold">Get 63% off FIXD</p>
    </div>
    <Banner buyUrl="/buy/sensor22" />
  </div>

  <Section center="true" verticalPadding="none">
    <div class="pt-5">
      <div class="block md:hidden">
        <div class="font-inter mt-[-8px]">
          <h1 class="text-lg sm:text-4xl title bold" id="bannerHeader">
            Decode check engine lights instantly
          </h1>
        </div>
      </div>
    </div>
  </Section>

  <div class="max-w-[1440px] mx-auto">
    <BuyBox {funnel} {gateway} />
  </div>

  <div id="asSeenOn">
    <AsSeenOn />
  </div>

  <Benefits />

  <OtherOBD2 {price}>
    <JumpToBuyBoxButton slot="cta">
      <span class="uppercase">Get FIXD NOW 63% OFF</span>
    </JumpToBuyBoxButton>
  </OtherOBD2>

  <Compatibility>
    <JumpToBuyBoxButton slot="cta" maxWidth={56}>
      <span class="uppercase">BUY FIXD NOW</span>
    </JumpToBuyBoxButton>
  </Compatibility>

  <LiveReviews />

  <TryRiskFree percentage={63}>
    <JumpToBuyBoxButton slot="cta" size="medium">
      <span class="uppercase">Get FIXD NOW 63% OFF</span>
    </JumpToBuyBoxButton>
  </TryRiskFree>

  <Mission />

  <FAQ />

  <About />

  <Footer />
</main>
