<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  function* range(start, end, step = 1) {
    let state = start
    while (state <= end) {
      yield state
      state += step
    }
  }

  const years = Array.from(range(1950, new Date().getFullYear() + 1)).reverse()

  const makes = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Karma',
    'Kia',
    'Lamborghini ',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Porsche',
    'Ram',
    'Smart',
    'Subaru',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]
  const fuelTypes = ['Gas', 'Hybrid', 'Diesel', 'Electric']

  let year = null
  let make = null
  let fuelType = null

  function isCompatible(year, make, fuelType) {
    if (year === null || make === null || fuelType === null) return null
    if (year < 1996) return false
    if (fuelType === 'Diesel' && year < 2006) return false
    if (fuelType === 'Electric') return false
    return true
  }

  $: compatible = isCompatible(year, make, fuelType)
</script>

<Section center="true">
  <slot name="header">
    <h1 class="font-bold mb-2">¿No sabes si tu auto es compatible?</h1>
  </slot>

  <slot name="content" />

  <div class="compatibility">
    <form class="m-auto flex flex-col flex-nowrap gap-2">
      <div>
        <h4 class="text-left mb-1">Año</h4>
        <div class="form-control">
          <select bind:value={year} name="year" class="no-label">
            <option value={null}>Selecciona uno...</option>
            {#each years as year}
              <option value={year}>{year}</option>
            {/each}
          </select>
          <div class="select-arrow" />
        </div>
      </div>

      <div>
        <h4 class="text-left mb-1">Marca</h4>
        <div class="form-control">
          <select bind:value={make} name="make" class="no-label">
            <option value={null}>Selecciona uno...</option>
            {#each makes as make}
              <option value={make}>{make}</option>
            {/each}
          </select>

          <div class="select-arrow" />
        </div>
      </div>

      <div>
        <h4 class="text-left mb-1">Tipo de combustible</h4>
        <div class="form-control">
          <select bind:value={fuelType} name="fuel-type" class="no-label">
            <option value={null}>Selecciona uno...</option>
            {#each fuelTypes as fuelType}
              <option value={fuelType}>{fuelType}</option>
            {/each}
          </select>

          <div class="select-arrow" />
        </div>
      </div>
    </form>
    <p class="text-base">
      {#if compatible === null}
        <!-- Nothing -->
      {:else if compatible}
        <p class="text-green my-2">Sí! es compatible con su vehículo.</p>
        <JumpToBuyBoxButton slot="cta" size="medium">
          <span class="uppercase">OBTÉN FIXD AHORA CON UN 67% DE DESCUENTO</span
          >
        </JumpToBuyBoxButton>
      {:else}
        <p class="not-compatible">
          Lo sentimos, tu coche no es compatible con FIXD.
        </p>
        <slot name="text-green" />
      {/if}
    </p>
  </div>
</Section>
