<script>
  import Benefits from '@/components/landing-page/Benefits.svelte'
  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import AsSeenOn from '@/components/landing-page/AsSeenOn.svelte'
  import OtherOBD2 from '@/components/landing-page/OtherOBD2.svelte'
  import TryRiskFree from '@/components/landing-page/TryRiskFree.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'
  import Mission from '@/components/landing-page/Mission.svelte'
  import FAQ from '@/components/landing-page/FAQ.svelte'
  import About from '@/components/landing-page/About.svelte'
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Banner from '@/components/landing-page/Banner.svelte'
  import Experiment from '@/components/Experiment.svelte'

  import BenefitsV1 from '@/pages/scorpio/landing_page/BenefitsV1.svelte'
  import BenefitsV2 from '@/pages/scorpio/landing_page/BenefitsV2.svelte'
  import BenefitsV3 from '@/pages/scorpio/landing_page/BenefitsV3.svelte'

  import {
    Lightning,
    SalesTag,
    ShippingTruck,
    Certificate,
  } from '@/components/icons'

  import BuyBoxL007 from '@/pages//scorpio/components/L007/BuyBoxL007.svelte'
  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Money from '@/util/money.mjs'

  export let funnel
  export let gateway

  // TODO: load this from funnel properties rather than hard-coding
  const price = new Money({ amount: 19.99, currency: 'USD' })
</script>

<main class="md:pb-0">
  <div class="hidden md:block">
    <div class="banner bg-green py-2.5">
      <div class="flex justify-evenly w-100 text-white font-bold font-inter">
        <div class="flex items-center">
          <div class="mr-3">
            <SalesTag color="white" />
          </div>
          <p class="text-xl">Get 67% off!</p>
        </div>
        <div class="flex basis-1/3 border-x justify-center item-center">
          <div class="mr-3">
            <ShippingTruck color="white" />
          </div>
          <p class="text-xl">Free Shipping</p>
        </div>
        <div class="flex">
          <div class="mr-3">
            <Certificate color="white" />
          </div>
          <p class="text-xl">Free 1 Year Warranty</p>
        </div>
      </div>
    </div>
    <Banner />
  </div>

  <div class="block md:hidden">
    <div class="flex font-inter items-center leading-tight">
      <div class="bg-yellow py-1 px-2 h-8">
        <p class="uppercase font-bold text-10 text-center">
          Lightning <br /> deal
        </p>
      </div>
      <div class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full">
        <Lightning color="white" size="sm" />
        <p class="text-white my-0 text-center font-semibold text-xs">
          67% OFF + FREE SHIPPING + FREE WARRANTY
        </p>
      </div>
    </div>
    <Banner showMenu={true} height="small" fillShoppingCardIcon={false} />
  </div>

  <Section center="true" verticalPadding="none">
    <div class="pt-5">
      <div class="block md:hidden">
        <div class="font-inter mt-[-8px]">
          <h1 class="text-lg sm:text-4xl title bold" id="bannerHeader">
            Decode check engine lights instantly
          </h1>
        </div>
      </div>
    </div>
  </Section>

  <div class="max-w-[1440px] mx-auto">
    <BuyBoxL007 {funnel} {gateway} />
  </div>

  <div id="asSeenOn">
    <AsSeenOn />
  </div>

  <Experiment name="LP-2024-11-05-Features" let:value>
    {#if value === 'LP-V1'}
      <BenefitsV1 />
    {:else if value === 'LP-V2'}
      <BenefitsV2 />
    {:else if value === 'LP-V3'}
      <BenefitsV3 />
    {:else}
      <Benefits />
    {/if}
  </Experiment>

  <OtherOBD2 {price}>
    <JumpToBuyBoxButton slot="cta">
      <span class="uppercase">Get FIXD NOW 67% OFF</span>
    </JumpToBuyBoxButton>
  </OtherOBD2>

  <Compatibility>
    <JumpToBuyBoxButton slot="cta" maxWidth={56}>
      <span class="uppercase">BUY FIXD NOW</span>
    </JumpToBuyBoxButton>
  </Compatibility>

  <LiveReviews />

  <TryRiskFree>
    <JumpToBuyBoxButton slot="cta" size="medium">
      <span class="uppercase">Get FIXD NOW 67% OFF</span>
    </JumpToBuyBoxButton>
  </TryRiskFree>

  <Mission />

  <FAQ />

  <About />

  <Footer />
</main>
